<template>
    <title-bar :title="$t('navigations.itemCategories')"/>
    <resource-listing :resource="$EntityOptions" param-type="ITEM_CATEGORY" base-url="item-categories" add-button="messages.addNew" delete-message="messages.itemCategoryDelete"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
